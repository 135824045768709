.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
/* =================== */
#root .tbl_view_head2,#root .tbl_view_head{
  padding: 16px 32px;
  box-sizing: border-box; 
}
.tbl_view >li >div:nth-child(2),.tbl_view >li >div:first-child,
.tbl_view_head >div:first-child,.tbl_view_head >div:nth-child(2){
    flex:0 0 28%;
    text-align: left;
    padding: 0 5px;
    
}
.tbl_view2 >li >div,
.tbl_view_head2 >div{  
    flex:0 0 28%;  
    text-align: left;
    padding: 0 5px;
    font-weight: 500;
}
.tbl_view2 >li >div:last-child,.tbl_view >li >div:last-child,
.tbl_view_head2 >div:last-child,.tbl_view_head >div:last-child{ 
  text-align: right;
  flex:0 1 auto;
  margin-left: auto;
}
.hFull_screen{
  min-height: calc(100vh - 170px);
  border:1px solid rgba(0,0,0,0.2);
  box-shadow: none !important;
  border-radius: 8px !important;
  font-weight: 500;
}
.hFull_screen span,.hFull_screen div{
  font-weight: 500;
  font-size: 12px;
}
[data-testid="EditIcon"]{
  background: url(../public/edit.svg) no-repeat center;
}
[data-testid="EditIcon"] *{
  display: none;
}
[data-testid="DeleteIcon"]{
  background: url(../public/delete.svg) no-repeat center ;
}
[data-testid="DeleteIcon"] *,[data-testid="InfoIcon"] *{
  display: none;
}
[data-testid="EditIcon"]+[data-testid="DeleteIcon"]{
  margin-left: 10px;
}
body [data-testid="InfoIcon"]{
  background: url(../public/info.svg) no-repeat center ;
  width: 16px;
  height: 16px;
}
.d-none{
  display: none !important;
}
.pr-3{
  padding-right: 1rem;
}
.text-capitalize{
  text-transform: capitalize !important;
}
.label_css form label.MuiInputLabel-animated{
  background: white;
  padding: 0 5px;
}
.btn_position_end{
  justify-self: flex-end !important;
}
.mr-2{
  margin-right: 8px; 
}
.mr-1{
  margin-right: 4px;
}
body .title_icn{
  border-radius: 0 !important;
  display: inline-block;
  vertical-align: bottom;
  margin-right: 8px;
  width: 30px;
  height: 33px;
}
.rbac_content_header h5{
  font-size: 28px;
  font-weight: 700;
}
.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0,0,0,0.2) !important;
}

label#demo-simple-select-label {
  color: rgba(0,0,0,0.2) !important;
}

body .title_icn img{
  object-fit: contain;
}
.MuiPaper-root {
  box-shadow: none !important;
}
#outlined-basic-helper-text {
  margin-left: 4px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
